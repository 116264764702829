@tailwind base;
@tailwind components;
@tailwind utilities;

.main-div{
  background-image: url("./assests/bg-img.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%  ;
}

.button {
  display: flex;
  justify-content: center;
  color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: linear-gradient(135deg, #e0f7fa 0%, #81d4fa 1%, #039be5 100%);
  transition: all 0.3s ease-in-out 0s;
  box-shadow: rgba(193, 244, 246, 0.698) 0px 0px 0px 0px;
  animation: 1.2s cubic-bezier(0.8, 0, 0, 1) 0s infinite normal none running pulse;
  align-items: center;
  border: 0;
}

.button:is(:hover, :focus) {
  transform: scale(1.2);
}

@keyframes pulse {
  100% {
    box-shadow: 0 0 0 45px rgba(193,244,246,0);
  }
}

/* From Uiverse.io by Shoh2008 */ 
.loader {

 
  background-repeat: no-repeat;
  background-image: linear-gradient(#ddd 50%, #bbb 51%),
    linear-gradient(#ddd, #ddd), linear-gradient(#ddd, #ddd),
    radial-gradient(ellipse at center, #aaa 25%, #eee 26%, #eee 50%, #0000 55%),
    radial-gradient(ellipse at center, #aaa 25%, #eee 26%, #eee 50%, #0000 55%),
    radial-gradient(ellipse at center, #aaa 25%, #eee 26%, #eee 50%, #0000 55%);



 
  animation: shake 3s ease-in-out infinite;
  transform-origin: 60px 180px;
}

.loader:before {
 
  position: absolute;
  /* left: 5px; */
  /* top: 100%; */
  width: 7px;
  height: 5px;
  background: #aaa;
  border-radius: 0 0 4px 4px;
  box-shadow: 102px 0 #aaa;
}

.loader:after {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  margin: auto;
  bottom: 20px;
  background-color: #bbdefb;
  background-image: linear-gradient( to right, #0004 0%, #0004 49%, #0000 50%, #0000 100% ),
    linear-gradient(135deg, #64b5f6 50%, #039be5 51%);
  background-size: 30px 100%, 90px 80px;
  border-radius: 50%;
  background-repeat: repeat, no-repeat;
  background-position: 0 0;
  box-sizing: border-box;
  border: 10px solid #DDD;
  box-shadow: 0 0 0 4px #999 inset, 0 0 6px 6px #0004 inset;
  animation: spin 3s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg)
  }

  50% {
    transform: rotate(360deg)
  }

  75% {
    transform: rotate(750deg)
  }

  100% {
    transform: rotate(1800deg)
  }
}

@keyframes shake {
  65%, 80%, 88%, 96% {
    transform: rotate(0.5deg)
  }

  50%, 75%, 84%, 92% {
    transform: rotate(-0.5deg)
  }

  0%, 50%, 100% {
    transform: rotate(0)
  }
}